import { useRef, useEffect, useState } from "react";
import cn from "classnames";
import "./Vimeo.scss";

const Vimeo = () => {
  const [activeModal, setActiveModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const iframeRef = useRef();
  const iframeModalRef = useRef();

  const setSize = () => {
    if (!iframeRef.current) return;
    let width = window.innerWidth;
    let height = window.innerHeight;
    if (width > 768) {
      width = height = Math.max(width, height);
    }
    iframeRef.current.width = width;
    iframeRef.current.height = height;
  };

  const onClick = () => {
    setActiveModal(true);
    if (iframeModalRef.current) {
      if (isPlaying) {
        iframeModalRef.current?.pause();
        setIsPlaying(false);
      } else {
        iframeModalRef.current?.play();
        setIsPlaying(true);
      }
    }
  };

  useEffect(() => {
    setSize();
  
    window.addEventListener("resize", setSize);
    return () => window.removeEventListener("resize", setSize);
  }, []);

  return (
    <div className="vimeo-main">
      {!activeModal && (
        <video
          title="EliteWebie"
          className="vimeo-main-video"
          ref={iframeRef}
          autoPlay={true}
          loop
          muted
          playsInline
          src="./images/designers/full-promo-video-final.mp4"
          width="640"
          height="360"
        />
      )}
      <div className="vimeo-main-wrap" onClick={onClick}></div>
      <video
        ref={iframeModalRef}
        controls
        autoPlay={false}
        muted
        playsInline
        className="vimeo-main-wrap-video"
        title="EliteWebie Showreel"
        src="./images/designers/full-promo-video-final.mp4"
        width="100%"
        height="100%"
        style={{ opacity: activeModal ? 1 : 0 }}
      />

      {!activeModal && (
        <div
          onClick={onClick}
          className={cn(
            "vimeo-play-btn",
            activeModal && "vimeo-play-btn--active"
          )}
        >
          <div className="triangle"></div>
        </div>
      )}
    </div>
  );
};

export default Vimeo;
