import axios from "axios";

const URL = "http://localhost:3001/api/v1/projects/getallprojects";

export const getProjects = async () => {
  return axios({
    url: URL,
    method: "GET",
  }).then((response) => response.data);
};
