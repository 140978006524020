import Home from "./views/Home/Home";
import { useEffect } from "react";
import { ModalContextProvider } from "./components/Modal/Modal";
import BreakpointsContextProvider from "./context/breakpointsContext";
import renderer from "Animator/js/renderer";
import { setCookie } from "nookies";

const App = () => {
  useEffect(() => {
    renderer.startRender();
    renderer.subscribeMouse();

    return () => {
      renderer.stopRender();
      renderer.unsubscribeMouse();
    };
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search || "");
    const referral = searchParams.get("ref") || searchParams.get("utm_source");

    if (referral) {
      setCookie(null, "referral", referral, {
        maxAge: 60 * 60 * 24 * 30, // month
      });
    }
  }, []);

  return (
    <ModalContextProvider>
      <BreakpointsContextProvider>
        <Home />
      </BreakpointsContextProvider>
    </ModalContextProvider>
  );
};

export default App;
