class DetectKeyboard {
  element;
  cb;
  constructor(element, cb) {
    this.element = element;
    this.cb = cb;
    this.start();
  }

  start() {
    this.element.addEventListener("keydown", this.listener);
  }

  destroy() {
    this.element.removeEventListener("keydown", this.listener);
  }

  listener = function (e) {
    const key = e.keyCode;

    const dir = key === 40 || key === 32 ? 1 : key === 38 ? -1 : null;

    if (dir) {
      if (typeof this.cb === "function") {
        this.cb({ dir, wheel: dir === 1 ? 100 : -100 });
      }
      this.element.dispatchEvent(
        new CustomEvent("keyboard", {
          bubbles: true,
          detail: { dir },
        })
      );
    }
  }.bind(this);
}

export default DetectKeyboard;
