const Icon = ({ id, onClick, className }) => {
  switch (id) {
    case "logo":
      return (
        <img
          width="117"
          height="42"
          id="logo"
          viewBox="0 0 85.514 33.754"
          src="./images/logo-done.webp"
          className={className}
          onClick={onClick}
        />
      );

    default:
      break;
  }
};

export default Icon;
