import "./Portfolio.scss";
import "./Anim.scss";
import { $t } from "i18n/i18n";
import { useEffect, useRef, useState } from "react";
import { Timeline } from "./timeline";
import { itl } from "Animator/js/utils/itl";
import { useTransform } from "Controller/hooks/useTransform";
import { screens } from "../../constants";
import cn from "classnames";
import Canvas from "./Canvas/Canvas";
import { context } from "Controller/utils/context";
import Slider from "./components/Slider/Slider";
import { useContext } from "react";
import Video from "components/UI/Video/Video";
import FewPeople from "./components/FewPeople/FewPeople";
import { BsArrowDown } from "react-icons/bs";
import { CursorContext, cursorStyles } from "components/Cursor/Cursor";
import { useStickToMouse } from "Animator/js/react/hooks/useMouse/useStickToMouse";
import { getProjects } from "api/getProjects";

const text = [
  $t("pages.following.start_your_project"),
  $t("pages.following.start_your_project"),
  $t("pages.following.start_your_project"),
  $t("pages.following.start_your_project"),
];

const Portfolio = function Portfolio() {
  const { setCursorStyle } = useContext(CursorContext);

  const [sliderProps, setSliderProps] = useState({ index: 0, length: 0 });

  const [following, setFollowing] = useState(false);
  const svgRef = useRef();
  const pathRef = useRef();
  const lineRef = useRef();
  const rect = useRef();
  const follParentRef = useRef();
  const cubeRef = useRef();
  const canvasRef = useRef();
  const armRef = useRef();
  const fewPeopleRef = useRef();
  const textRef = useRef();
  const textBelowRef = useRef();

  const { target: mouseTarget } = useStickToMouse();

  const { parent, target } = useTransform(
    { onChange },
    { id: screens.PORTFOLIO }
  );
  function onChange({ wheel }) {
    const refs = initRefs();
    if (!refs.mounted) {
      return;
    }
    const timeline = Timeline(refs);
    const t = itl(timeline, wheel);
    refs.path.style.cssText = `stroke-dashoffset: ${t.svg.offset}`;
    refs.rec.style.cssText = `width: ${t.line.width}px; height: ${t.line.height}%;`;
    setFollowing(t.followedCont.opacity === 1);

    refs.text.style.cssText = `transform: translateX(${t.text.translateX}px) rotate(0deg)`;
    refs.textBelow.style.cssText = `transform: translateX(${t.textBelow.translateX}px) rotate(0deg)`;

    refs.arm.style.transform = `scale(${t.arm.scale})`;

    const header = document.querySelector(".header");
    if (
      header &&
      t.followedCont.opacity === 1 &&
      context.ids[context.active] === screens.PORTFOLIO
    ) {
      header.classList.add("-force-following-color");
    } else {
      header.classList.remove("-force-following-color");
    }
  }

  function initRefs() {
    const par = parent.current;
    const tar = target.current;
    const rec = rect.current;
    const svg = svgRef.current;
    const path = pathRef.current;
    const line = lineRef.current;
    const follParent = follParentRef.current;
    const cube = cubeRef.current;
    const canvas = canvasRef.current;
    const arm = armRef.current;
    const fewPeople = fewPeopleRef.current;
    const text = textRef.current;
    const textBelow = textBelowRef.current;
    const mounted =
      par &&
      tar &&
      rec &&
      svg &&
      path &&
      line &&
      follParent &&
      cube &&
      canvas &&
      arm &&
      fewPeople &&
      text &&
      textBelow;
    return {
      mounted,
      par,
      tar,
      rec,
      svg,
      path,
      line,
      follParent,
      cube,
      canvas,
      arm,
      fewPeople,
      text,
      textBelow,
    };
  }

  const portfolioContainer = useRef();
  const followRefs = useTransform("sticky", {
    id: screens.PORTFOLIO,
    parent: follParentRef,
    upperElements: [fewPeopleRef, portfolioContainer, target, lineRef],
  });

  const hadlerRoute = (url) => {
    window.open(url, "_blank");
  };

  const [Projects, setProjects] = useState([]);
  useEffect(() => {
    setProjects([
      {
        title: ["DD", " Landing Page"],
        poster: "images/designers/large.webp",
        link: "",
      },
      {
        title: ["DataRepo", " Landing Page"],
        poster: "projects/datarepo.png",
        link: "",
      },
      {
        title: ["Save Bucks", " Landing Page"],
        poster: "images/designers/small.webp",
        link: "",
      },
      {
        title: ["Flaro", " Landing Page"],
        poster: "images/designers/template.webp",
        link: "",
      },
      {
        title: ["Your Logo", " Landing Page"],
        poster: "images/designers/rental-website.webp",
        link: "",
      },
      {
        title: ["Small Tasks", " Landing Page"],
        poster: "images/designers/hero-section-small.webp",
        link: "",
      }
    ]);
  }, []);

  const sticky = useTransform("sticky", {
    id: screens.PORTFOLIO,
    parent: portfolioContainer,
    cropMax: true,
    upperElements: [fewPeopleRef],
  });

  return (
    <section ref={parent} className="portfolio" id="portfolio">
      <div ref={fewPeopleRef}>
        <FewPeople />
      </div>
      <div ref={portfolioContainer} className="portfolio__container">
        <div ref={sticky.target} className="portfolio__sticky">
          {Projects.length > 0 && (
            <Slider
              className="portfolio__slider"
              slides={Projects}
              onChange={(props) => setSliderProps(props)}
            >
              <p className="pagination-number">
                {sliderProps.index + 1 < 10
                  ? "0" + (sliderProps.index + 1)
                  : sliderProps.index + 1}{" "}
                /{" "}
                {Projects.length < 10 ? "0" + Projects.length : Projects.length}
              </p>
            </Slider>
          )}
        </div>
      </div>
      <div ref={target} className="portfolio__animation">
        <div className="arm">
          <div ref={armRef}>
            <Video
              src={"./video/portfolio/hand.mp4"}
              poster={"./video/portfolio/hand.webp"}
              width={140}
              height={140}
            />
          </div>
        </div>
        <svg
          ref={svgRef}
          className="line"
          width="1728"
          height="507"
          viewBox="0 0 1728 507"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            ref={pathRef}
            d="M1636.5 -0.5C1636.67 46.5 1689.5 135.5 1442.5 74C1223.74 19.5295 1175 151.5 1232.5 117C1257.48 102.013 1263.5 63.7338 1171.5 82C1071.3 101.895 863.504 163 864.504 293V507.5"
            strokeDasharray="2500"
            strokeDashoffset="2500"
            stroke="white"
          />
        </svg>
      </div>
      <div ref={lineRef} className="portfolio__line">
        <div ref={rect}></div>
      </div>
      <div
        ref={follParentRef}
        className={cn("portfolio__following", following ? "-active" : "")}
      >
        <div ref={followRefs.target} className="following-sticky">
          <div ref={canvasRef} className="canvas">
            <Canvas parent={followRefs.target} isActive={following} />
          </div>
          <div className="following__cube">
            <div ref={cubeRef} className="cude__wrapper">
              <div ref={textRef} className="cude__center">
                {text?.map((_, index) => (
                  <h1 key={index} className="cude-text-top">
                    {_}
                  </h1>
                ))}
              </div>

              <div className="grid_item is--resources">
                <img
                  src="./images/portfolio/scroll-section-image.webp"
                  loading="lazy"
                  alt="scroll-section"
                  className="scroll-section-img"
                />
              </div>

              <div
                ref={textBelowRef}
                style={{zIndex: 3}}
                className="cude__center-b"
              >
                {text
                  .filter((_, index) => index <= 0)
                  .map((_, i) => (
                    <h1 className="cude-text-bottom" key={i}>
                      {_}
                    </h1>
                  ))}

                <div
                  ref={mouseTarget}
                  className="toggle-btn"
                  onMouseEnter={() => setCursorStyle(cursorStyles.HOVER_NAV)}
                  onMouseLeave={() => setCursorStyle(cursorStyles.DEFAULT)}
                >
                  <BsArrowDown
                    size={32}
                    color="black"
                    style={{ transform: "rotate(-45deg)", fontWeight: 900 }}
                  />
                </div>
                {text?.map((_, index) => (
                  <h1 className="cude-text-bottom" key={index}>
                    {_}
                  </h1>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
