export const context = {
  active: 0,
  ids: "main",
  controller: null,
  childrenArray: null,
  sections: null,
  prevsections: null,
  changing: false,
  globalScrollHeight: 0,
  externalChange: false,
  setExternalChanging: null,
  wheel: 0,
  wheelTo: 0,
  activeForm: false,
};
