import "./Jumper.scss";
import "./Anim.scss";


const Jumper = () => {
  return (
    <div className="jumper">
      <div className="jumper__circle">
        <div className="logo">
          <svg
            width="117"
            height="28"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 87.55 43.38"
            fill="none"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth="1.25px"
          >
            <path
              stroke="currentColor"
              d="M101.32,145.72l-3.07-.09a3.92,3.92,0,0,1-1.81,2.12,6,6,0,0,1-3,.74,5.34,5.34,0,0,1-4.07-1.57,5.77,5.77,0,0,1-1.48-4.14v-.24H100.5l-4.15-16.2A16.1,16.1,0,0,0,93,126a14.37,14.37,0,0,0-7.36,1.82,12.51,12.51,0,0,0-4.84,5.07,16.15,16.15,0,0,0-1.71,7.61q0,6.74,3.78,10.58t10.4,3.86a15.09,15.09,0,0,0,9-2.48c.24-.17.48-.35.7-.54Zm-11.9-11.87a5.21,5.21,0,0,1,3.72-1.41,5,5,0,0,1,3.64,1.38,4.73,4.73,0,0,1,1.45,3.54H87.85A5,5,0,0,1,89.42,133.85Z"
              transform="translate(-78.75 -111.92)"
            />
            <polygon
              stroke="currentColor"
              points="78.25 0.3 67.26 43.08 52.93 43.08 45.75 13.58 39.31 43.08 24.95 43.08 24.16 40 22.57 33.8 21.75 30.62 17.6 14.42 13.98 0.3 27.44 0.3 32.75 32.58 40.75 0.58 50.75 0.58 59.75 32.58 64.79 0.3 78.25 0.3"
            />
            <circle  stroke="currentColor" cx="80.75" cy="36.58" r="6.5" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Jumper;
