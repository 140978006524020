// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-component::-webkit-media-controls-panel {
  display: none !important;
}
.video-component::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none !important;
}
.video-component::-webkit-media-controls {
  display: none;
}
.video-component > * {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/Video/Video.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;AAAR;AAGI;EACI,wBAAA;EACA,mCAAA;AADR;AAII;EACI,aAAA;AAFR;AAKI;EACI,aAAA;AAHR","sourcesContent":[".video-component {\n    &::-webkit-media-controls-panel {\n        display: none !important;\n    }\n\n    &::-webkit-media-controls-start-playback-button {\n        display: none !important;\n        -webkit-appearance: none !important;\n    }\n\n    &::-webkit-media-controls {\n        display: none;\n    }\n\n    >* {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
