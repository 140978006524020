// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animate-link {
  position: relative;
  overflow: hidden;
  display: flex;
}
.animate-link .hidden {
  opacity: 0;
  visibility: hidden;
}
.animate-link-tr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.animate-link-tr > * {
  transition: transform 0.5s ease;
}
.animate-link-tr > :first-child {
  transform: none;
}
.animate-link-tr > :last-child {
  transform: translateY(50%) rotate(-10deg);
}
.animate-link:hover .animate-link-tr > :first-child {
  transform: translateY(-150%) rotate(-10deg);
}
.animate-link:hover .animate-link-tr > :last-child {
  transform: translateY(-100%) rotate(0deg);
}
.animate-link .hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Menu/AnimateLink.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;AACJ;AACI;EACI,UAAA;EACA,kBAAA;AACR;AAEI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAAR;AAGQ;EACI,+BAAA;AADZ;AAIQ;EACI,eAAA;AAFZ;AAKQ;EACI,yCAAA;AAHZ;AAUY;EACI,2CAAA;AARhB;AAWY;EACI,yCAAA;AAThB;AAcI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;AAZR","sourcesContent":[".animate-link {\n    position: relative;\n    overflow: hidden;\n    display: flex;\n\n    .hidden {\n        opacity: 0;\n        visibility: hidden;\n    }\n\n    &-tr {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n\n\n        >* {\n            transition: transform .5s ease;\n        }\n\n        > :first-child {\n            transform: none;\n        }\n\n        > :last-child {\n            transform: translateY(50%) rotate(-10deg);\n        }\n\n    }\n\n    &:hover {\n        .animate-link-tr {\n            > :first-child {\n                transform: translateY(-150%) rotate(-10deg);\n            }\n\n            > :last-child {\n                transform: translateY(-100%) rotate(0deg);\n            }\n        }\n    }\n\n    .hover {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 100%;\n        height: 100%;\n        pointer-events: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
