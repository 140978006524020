const itl = (config, time) => {
  const timeline = config.timeline;
  const tInterface = config.interface;

  if (timeline.length < 2) {
    return console.error('[itl]: Minimum count of points "2"');
  }
  fillInterface();
  return tInterface;

  function fillInterface() {
    for (let key in tInterface) {
      const times = tInterface[key].$TIME;
      if (time < times.at(0)) {
        calc(key, [times.at(0)]);
      } else if (time >= times.at(-1)) {
        calc(key, [times.at(-1)]);
      } else {
        for (let i = 0; i < times.length - 1; i++) {
          if (time >= times.at(i) && time < times.at(i + 1)) {
            calc(key, [times.at(i), times.at(i + 1)]);
          }
        }
      }
    }

    function calc(key, times) {
      if (times.length === 1) {
        return (tInterface[key] = {
          ...timeline.filter((_) => _.$TIME === times[0])[0][key],
          $TIME: tInterface[key].$TIME,
        });
      }
      const tA = times[0];
      const tB = times[1];
      const pA = timeline.filter((_) => _.$TIME === tA)[0][key];
      const pB = timeline.filter((_) => _.$TIME === tB)[0][key];
      if (pA === undefined || pB === undefined) {
        console.error(
          `[ITL]: invalid $TIME in Interface "${key}, ${tA}, ${tB}"`
        );
        return;
      }
      const t = Math.max(Math.min(time, tB), tA);
      Object.keys(tInterface[key]).forEach((innerKey) => {
        if (key === "$TIME" || innerKey === "$TIME") {
          return;
        }
        tInterface[key][innerKey] =
          ((t - tA) / (tB - tA)) * (pB[innerKey] - pA[innerKey]) + pA[innerKey];
      });
    }
  }
};

export { itl };
