import DetectWheel from "./detectWheel";
import DetectSwipe from "./detectSwipe";
import DetectKeyboard from "./detectKeyword";

class Detector {
  swipe;
  scroll;
  keyboard;
  callback;

  constructor(dom, callback) {
    if (!dom) {
      console.error("[Detector]: dom element is required");
      return;
    }
    this.callback = callback || (() => {});
    this.scroll = new DetectWheel(dom, this.controlScroll.bind(this));
    this.swipe = new DetectSwipe(dom, this.controlScroll.bind(this));
    this.keyboard = new DetectKeyboard(dom, this.controlScroll.bind(this));
  }

  controlScroll(props) {
    const to = props;
    this.control(to);
  }
  control(to) {
    this.callback(to);
  }

  unmount() {
    this.scroll.unsubscribe();
    this.swipe.destroy();
    this.keyboard.destroy();
  }
}

export default Detector;
