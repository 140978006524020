import { createContext, useState, useEffect, useRef } from "react";
import { state } from "./utils/state";
import { swipe } from "./utils/swipe";
import { scroll } from "./utils/scroll";
import { context } from "./utils/context";
import Detector from "./detector/detector";
import cn from "classnames";
import "./index.scss";
import { setToRender, removeFromRender } from "./../Animator/js/renderer";

import { ModalContext } from "../components/Modal/Modal";
import { useContext } from "react";

export const ControllerContext = createContext();

const Controller = ({
  children,
  duration,
  externalDelay,
  externalDuration,
  fixedController,
}) => {
  const [prevSections, setPrevSections] = useState(null);
  const [sections, setSections] = useState(null);
  const [active, setActive] = useState(context.active);
  const [activeId, setActiveId] = useState();
  const [externalChanging, setExternalChanging] = useState(false);
  const { activeForm } = useContext(ModalContext);
  context.setExternalChanging = setExternalChanging;
  state.set({ duration, externalDelay, externalDuration });

  useEffect(() => {
    context.activeForm = activeForm;
  }, [activeForm]);

  const controller = useRef();
  const value = {
    active,
    setNewActive,
    activeId,
    sections,
    setSections,
    setPrevSections,
  };
  useEffect(() => {
    const label = `ControllerScrollRender${Date.now()}`;
    const detector = new Detector(document, setActiveOnScroll);
    context.controller = controller.current;
    setToRender({
      label,
      handler: () => scroll.renderTranslateInterpolation(),
    });
    return () => {
      detector.unmount();
      removeFromRender(label);
    };
  }, []);

  function setActiveOnScroll({ dir, wheel }) {
    if (!scroll.ready(context.sections)) {
      return;
    }
    if (context.activeForm) {
      return;
    }

    if (context.changing) {
      return;
    }

    const innerPos = swipe.getInnerPos(context.sections);
    const swipeDir = dir === 1 ? swipe.pos.BOTTOM : swipe.pos.TOP;
    if (innerPos === swipeDir || innerPos === swipe.pos.TOPBOTTOM) {
      if (state.validActive(context.active + dir)) {
        context.wheel = 0;
        scroll.resetWheelTo();
        setNewActive(context.active + dir, false);
      }
      return;
    }
    context.wheel = wheel;
    scroll.calcWheelTo();
  }
  function setNewActive(index, externalChange = true) {
    if (index === context.active) {
      return;
    }

    context.changing = true;
    context.active = index;
    context.externalChange = externalChange;
    setActive(index);
    setActiveId(context.ids[context.active]);
    setExternalChanging(externalChange);
  }

  useEffect(() => {
    context.prevsections = prevSections;
    context.sections = sections;
    swipe.swipe(prevSections, sections, () =>
      scroll.resetTranslate(context.sections)
    );
  }, [sections]);

  return (
    <ControllerContext.Provider value={value}>
      <div
        ref={controller}
        className={cn(
          "controller",
          fixedController ? "fixed-controller" : "",
          fixedController ? state.globalClassName(active) : "",
          state.externalChangingClass(externalChanging)
        )}
      >
        {children}
      </div>
    </ControllerContext.Provider>
  );
};

export default Controller;
