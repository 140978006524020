// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-controller {
  position: fixed;
  overflow: hidden;
}

.controller {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  isolation: isolate;
}
.controller .controller-sections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.controller .controller-sections .section {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
}
.controller .controller-sections .section-inner {
  position: relative;
  width: 100%;
}
.controller .controller-sections .section-black {
  background: #000;
}`, "",{"version":3,"sources":["webpack://./src/Controller/index.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AAEI;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEQ;EACI,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAAZ;AAEY;EACI,kBAAA;EACA,WAAA;AAAhB;AAGY;EACI,gBAAA;AADhB","sourcesContent":["\n.fixed-controller {\n    position: fixed;\n    overflow: hidden;\n}\n\n.controller {\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    isolation: isolate;\n\n    .controller-sections {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n\n        .section {\n            position: absolute;\n            top: 100%;\n            left: 0;\n            width: 100%;\n            height: 100%;\n\n            &-inner {\n                position: relative;\n                width: 100%;\n            }\n\n            &-black {\n                background: #000;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
