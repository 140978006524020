import "./Menu.scss";
import { $t } from "i18n/i18n";
import { useContext, useState } from "react";
import { ControllerContext } from "Controller/Controller";
import { getScreen } from "utils";
import { state } from "../../../Controller/utils/state";
import { CursorContext, cursorStyles } from "../../Cursor/Cursor";
import cn from "classnames";
import UnderLink from "../../UI/UnderLink/UnderLink";
import AnimateLink from "./AnimateLink";
import Video from "../../UI/Video/Video";

const menuHovers = [
  {
    className: "-hovered-1",
    video: "./video/menu/1.mp4",
    poster: "./video/menu/1-poster.webp",
  },
  {
    className: "-hovered-2",
    video: "./video/menu/2.mp4",
    poster: "./video/menu/3-poster.webp",
  },
  {
    className: "-hovered-3",
    video: "./video/menu/3.mp4",
    poster: "./video/menu/3-poster.webp",
  },
  {
    className: "-hovered-4",
    video: "./video/menu/4.mp4",
    poster: "./video/menu/4-poster.webp",
  }
];

const Menu = ({ isMenuShow, setIsMenuShow }) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const { setCursorStyle } = useContext(CursorContext);
  const { setNewActive } = useContext(ControllerContext);

  const routeToScreen = (link) => {
    const screen = getScreen(link);
    if (typeof screen === "number") {
      setNewActive(screen);
      setTimeout(() => {
        setIsMenuShow(false);
      }, state.externalDelay);
    }
  };

  return (
    <div className={`menu ${isMenuShow ? "-show" : ""}`}>
      <div className="menu__content">
        <div className="menu__social">
          <span className="m">{$t("components.menu.social.title")}</span>
          {$t("components.menu.social.links").map((_, i) => (
            <AnimateLink
              className="link"
              href={_.link}
              key={i}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setCursorStyle(cursorStyles.HOVER_NAV)}
              onMouseLeave={() => setCursorStyle(cursorStyles.DEFAULT)}
            >
              {_.name}
            </AnimateLink>
          ))}
        </div>
        <div className={cn("menu__img", menuHovers[hoveredIndex].className)}>
          {menuHovers.map((_, i) => (
            <div key={i} className="img-crop">
              <Video src={_.video} poster={_.poster} width={338} height={476} />
            </div>
          ))}
        </div>
        <div className="menu__folder">
          <span className="m">{$t("components.menu.nav.title")}</span>
          <div className="list">
            {$t("components.menu.nav.links").map((_, i) => (
              <AnimateLink
                key={i}
                onClick={() => routeToScreen(_.link)}
                onMouseEnter={() => {
                  setCursorStyle(cursorStyles.HOVER_NAV);
                  setHoveredIndex(i);
                }}
                onMouseLeave={() => setCursorStyle(cursorStyles.DEFAULT)}
              >
                {_.name}
              </AnimateLink>
            ))}
          </div>
        </div>
      </div>
      <div className="menu__text">
        <div className="mail">
          <a className="mail__text" href="#">
            {$t("components.menu.connect.text")}
          </a>
          <UnderLink
            className="underline"
            href="mailto:amitsingh4466019@gmail.com"
          >
            {$t("components.menu.connect.mail")}
          </UnderLink>
        </div>
        <div className="menu__cp">
          <p className="menu__cp_privacy">
            {$t("components.menu.connect.privacy")}
          </p>
          <p className="menu__cp_copyright">
            {$t("components.menu.connect.copyright")}
            <span>{new Date().getFullYear()}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Menu;
