import axios from "axios";

const URL = "https://api.elitewebie.com/api/v1/contacts/create";

export const sendForm = async (data) => {
  return axios({
    url: URL,
    method: "POST",
    data: data,
  }).then((response) => response.data);
};
